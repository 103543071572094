define("discourse/plugins/discourse-topic-tag-scheduler/discourse/initializers/topic-tag-schedule-fields", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-topic-tag-scheduler/discourse/lib/constants"], function (_exports, _pluginApi, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "topic-tag-schedule-fields",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.serializeOnCreate(_constants.STARTS_AT_FIELD);
        api.serializeToDraft(_constants.STARTS_AT_FIELD);
        api.serializeToTopic(_constants.STARTS_AT_FIELD, `topic.${_constants.STARTS_AT_FIELD}`);
        api.serializeOnCreate(_constants.ENDS_AT_FIELD);
        api.serializeToDraft(_constants.ENDS_AT_FIELD);
        api.serializeToTopic(_constants.ENDS_AT_FIELD, `topic.${_constants.ENDS_AT_FIELD}`);
      });
    }
  };
});