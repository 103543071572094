define("discourse/plugins/discourse-topic-tag-scheduler/discourse/connectors/after-title-and-category/schedule-topic-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "I18n", "discourse/plugins/discourse-topic-tag-scheduler/discourse/components/schedule-topic-modal", "discourse/plugins/discourse-topic-tag-scheduler/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _I18n, _scheduleTopicModal, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScheduleTopicButton extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        currentUser: currentUser1
      } = _ref;
      return currentUser1?.staff;
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    constructor() {
      super(...arguments);
      const startsAt1 = this.composerModel.get(`topic.${_constants.STARTS_AT_FIELD}`);
      if (startsAt1) {
        const endsAt1 = this.composerModel.get(`topic.${_constants.ENDS_AT_FIELD}`);
        this.composerModel.setProperties({
          [_constants.STARTS_AT_FIELD]: startsAt1,
          [_constants.ENDS_AT_FIELD]: endsAt1
        });
      }
    }
    updateDateRange(startsAt1, endsAt1) {
      this.composerModel.setProperties({
        [_constants.STARTS_AT_FIELD]: startsAt1?.toISOString() ?? "",
        [_constants.ENDS_AT_FIELD]: endsAt1?.toISOString() ?? ""
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateDateRange", [_object.action]))();
    openScheduleModal() {
      this.modal.show(_scheduleTopicModal.default, {
        model: {
          startsAt: this.composerModel.get(_constants.STARTS_AT_FIELD),
          endsAt: this.composerModel.get(_constants.ENDS_AT_FIELD),
          updateDateRange: this.updateDateRange
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "openScheduleModal", [_object.action]))();
    get label() {
      const startsAt1 = this.composerModel.get(_constants.STARTS_AT_FIELD);
      const endsAt1 = this.composerModel.get(_constants.ENDS_AT_FIELD);
      if (!startsAt1) {
        return _I18n.default.t("discourse_topic_tag_scheduler.schedule");
      }
      const formattedStartsAt1 = new Date(startsAt1).toLocaleDateString(_I18n.default.currentBcp47Locale, {
        day: "numeric",
        month: "short"
      });
      const formattedEndsAt1 = new Date(endsAt1).toLocaleDateString(_I18n.default.currentBcp47Locale, {
        day: "numeric",
        month: "short"
      });
      return `${formattedStartsAt1} - ${formattedEndsAt1}`;
    }
    get composerModel() {
      return this.args.outletArgs.model;
    }
    get isScheduled() {
      return !!this.composerModel.get(_constants.STARTS_AT_FIELD);
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @class="schedule-topic-button"
          @action={{this.openScheduleModal}}
          @translatedLabel={{this.label}}
          @icon={{if this.isScheduled "far-calendar-check" "far-calendar-plus"}}
        />
      
    */
    {
      "id": "rLme+LC/",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@class\",\"@action\",\"@translatedLabel\",\"@icon\"],[\"schedule-topic-button\",[30,0,[\"openScheduleModal\"]],[30,0,[\"label\"]],[52,[30,0,[\"isScheduled\"]],\"far-calendar-check\",\"far-calendar-plus\"]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-tag-scheduler/discourse/connectors/after-title-and-category/schedule-topic-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ScheduleTopicButton;
});