define("discourse/plugins/discourse-topic-tag-scheduler/discourse/components/schedule-topic-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/components/date-time-input-range", "discourse/lib/render-tag", "discourse-common/helpers/i18n", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template2, _dButton, _dModal, _dModalCancel, _dateTimeInputRange, _renderTag, _i18n, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScheduledModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "startsAt", [_tracking.tracked], function () {
      return this.args.model.startsAt ? moment(this.args.model.startsAt) : moment().add(this.siteSettings.topic_tag_scheduler_default_start_days_from_now, "days");
    }))();
    #startsAt = (() => (dt7948.i(this, "startsAt"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "endsAt", [_tracking.tracked], function () {
      return this.args.model.endsAt ? moment(this.args.model.endsAt) : moment().add(this.siteSettings.topic_tag_scheduler_default_duration_days, "days");
    }))();
    #endsAt = (() => (dt7948.i(this, "endsAt"), void 0))();
    onChangeDateRange(range1) {
      this.startsAt = range1.from;
      this.endsAt = range1.to;
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeDateRange", [_object.action]))();
    updateDateRange() {
      this.args.model.updateDateRange(this.startsAt, this.endsAt);
      this.args.closeModal();
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateDateRange", [_object.action]))();
    removeSchedule() {
      this.args.model.updateDateRange(null, null);
      this.args.closeModal();
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeSchedule", [_object.action]))();
    get schedulingDescription() {
      if (!this.startsAt || !this.endsAt) {
        return "";
      }
      return (0, _template2.htmlSafe)(_I18n.default.t("discourse_topic_tag_scheduler.schedule_modal.description", {
        tag: (0, _renderTag.default)(this.siteSettings.topic_tag_scheduler_tag),
        start: (0, _template2.htmlSafe)(`<strong>${moment(this.startsAt).format("LL")}</strong>`),
        end: (0, _template2.htmlSafe)(`<strong>${moment(this.endsAt).format("LL")}</strong>`)
      }));
    }
    get submitDisabled() {
      return !this.startsAt || !this.endsAt;
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="schedule-topic-modal"
          @title={{i18n "discourse_topic_tag_scheduler.schedule_modal.title"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <div class="schedule-topic-modal__dates">
              <DateTimeInputRange
                @from={{this.startsAt}}
                @to={{this.endsAt}}
                @onChange={{this.onChangeDateRange}}
              />
            </div>
    
            <p>{{this.schedulingDescription}}</p>
          </:body>
          <:footer>
            <DButton
              @action={{this.updateDateRange}}
              @label="save"
              @class="btn-primary"
              @disabled={{this.submitDisabled}}
            />
    
            <DModalCancel @close={{@closeModal}} />
            <div style="flex:1"></div>
    
            {{#if @model.startsAt}}
              <DButton
                @action={{this.removeSchedule}}
                @label="delete"
                @class="btn-danger"
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "SjqEhi6J",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"schedule-topic-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_topic_tag_scheduler.schedule_modal.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"schedule-topic-modal__dates\"],[12],[1,\"\\n          \"],[8,[32,2],null,[[\"@from\",\"@to\",\"@onChange\"],[[30,0,[\"startsAt\"]],[30,0,[\"endsAt\"]],[30,0,[\"onChangeDateRange\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,2],[12],[1,[30,0,[\"schedulingDescription\"]]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],null,[[\"@action\",\"@label\",\"@class\",\"@disabled\"],[[30,0,[\"updateDateRange\"]],\"save\",\"btn-primary\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n\\n        \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n        \"],[10,0],[14,5,\"flex:1\"],[12],[13],[1,\"\\n\\n\"],[41,[30,2,[\"startsAt\"]],[[[1,\"          \"],[8,[32,3],null,[[\"@action\",\"@label\",\"@class\"],[[30,0,[\"removeSchedule\"]],\"delete\",\"btn-danger\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-tag-scheduler/discourse/components/schedule-topic-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _dateTimeInputRange.default, _dButton.default, _dModalCancel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ScheduledModal;
});